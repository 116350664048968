import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Card, Row, Col, Modal, Button } from "react-bootstrap";
import ProductDetailSheet from "../components/ProductDetailSheet"; // Убедитесь, что этот компонент реализован

const OrderDetailPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `https://magaz05.tw1.ru/php/get_order_details.php?order_id=${orderId}`
        );
        setOrderDetails(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleShowProductDetails = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const checkRegistration = () => {
    const customerId = localStorage.getItem("customerId");
    if (!customerId) {
      navigate("/register");
      return false;
    }
    return true;
  };

  const addToCart = async (product) => {
    if (!checkRegistration()) return;

    try {
      const customerId = localStorage.getItem("customerId");
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/add_to_cart.php",
        {
          customer_id: customerId,
          product_id: product.product_id,
          user_id: product.user_id,
          variant_id: product.variant_id || null,
          quantity: 1, // Вы можете изменить это, чтобы разрешить выбор количества
        }
      );
      if (response.status === 200) {
        alert("Товар добавлен в корзину");
      } else {
        alert("Ошибка при добавлении товара в корзину");
      }
    } catch (error) {
      alert("Ошибка при добавлении товара в корзину");
    }
  };

  const addToFavorites = async (product) => {
    if (!checkRegistration()) return;

    try {
      const customerId = localStorage.getItem("customerId");
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/add_to_favorites.php",
        {
          customer_id: customerId,
          product_id: product.product_id,
        }
      );
      if (response.status === 200) {
        alert("Товар добавлен в избранное");
      } else {
        alert("Ошибка при добавлении товара в избранное");
      }
    } catch (error) {
      alert("Ошибка при добавлении товара в избранное");
    }
  };

  if (loading) {
    return <p>Загрузка...</p>;
  }

  if (error) {
    return <p>Ошибка при загрузке данных: {error.message}</p>;
  }

  const orderDate =
    orderDetails && orderDetails.order_date
      ? new Date(orderDetails.order_date).toLocaleDateString()
      : "N/A";

  return (
    <Container>
      <h1>Детали заказа</h1>
      {orderDetails ? (
        <div>
          <h2>Номер заказа: {orderDetails.id}</h2>
          <p>Дата заказа: {orderDate}</p>
          <p>Итого: {orderDetails.total} ₽</p>
          <h3>Товары:</h3>
          <Row>
            {orderDetails.items.map((item) => (
              <Col key={item.product_id} xs={12} md={6} lg={4}>
                <Card className="mb-4">
                  <Card.Img
                    variant="top"
                    src={`https://magaz05.tw1.ru/images/${item.image}`}
                  />
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>Количество: {item.quantity}</Card.Text>
                    <Card.Text>Цена: {item.price} ₽</Card.Text>
                    <Button
                      variant="success"
                      onClick={() => addToCart(item)}
                      className="ml-2"
                    >
                      Добавить в корзину
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => addToFavorites(item)}
                      className="ml-2"
                    >
                      Добавить в избранное
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <p>Детали заказа не найдены.</p>
      )}
      {selectedProduct && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductDetailSheet product={selectedProduct} />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default OrderDetailPage;
