import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Modal, Container, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OrderHistoryPage from "./OrderHistoryPage";
import RegisterPage from "./RegisterPage";
import ProductListPage from "./ProductListPage";

const ProfilePage = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [originalPhone, setOriginalPhone] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    const customerId = localStorage.getItem("customerId");
    if (customerId) {
      try {
        const response = await axios.get(
          `https://magaz05.tw1.ru/php/get_customer.php?customerId=${customerId}`
        );
        const userData = response.data;
        setName(userData.name || "");
        setPhone(userData.phone || "");
        setOriginalName(userData.name || "");
        setOriginalPhone(userData.phone || "");

        // Логирование данных о пользователе в консоль
        console.log("Данные профиля загружены:", userData);
      } catch (error) {
        setError("Ошибка при получении данных о пользователе");
      }
    } else {
      setError("ID пользователя не найден");
    }
  };


  const sendCode = async () => {
    try {
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/send_code.php",
        { phone }
      );
      if (response.status === 200) {
        alert("Код отправлен на ваш номер телефона");
      } else {
        setError("Ошибка отправки кода");
      }
    } catch (error) {
      setError("Ошибка отправки кода");
    }
  };

  const verifyAndSaveCode = async () => {
    try {
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/verify_code.php",
        { phone, code }
      );
      if (response.status === 200 && response.data.success) {
        updateUserData();
        setShowVerifyModal(false);
      } else {
        setError("Неверный код");
        restoreOriginalData();
      }
    } catch (error) {
      setError("Ошибка проверки кода");
      restoreOriginalData();
    }
  };

  const restoreOriginalData = () => {
    setName(originalName);
    setPhone(originalPhone);
  };

  const updateUserData = async () => {
    const customerId = localStorage.getItem("customerId");
    if (customerId) {
      try {
        const response = await axios.post(
          "https://magaz05.tw1.ru/php/update_customer.php",
          {
            customerId,
            name,
            phone,
          }
        );
        if (response.status === 200) {
          alert("Данные успешно обновлены");
        } else {
          setError("Ошибка при обновлении данных");
          restoreOriginalData();
        }
      } catch (error) {
        setError("Ошибка при обновлении данных");
        restoreOriginalData();
      }
    }
  };

  const logout = async () => {
    console.log("Выход из аккаунта..."); // Логируем действие выхода
    localStorage.clear(); // Очищаем данные пользователя
    console.log(
      "Локальное хранилище очищено. Перенаправление на страницу регистрации."
    );
    navigate("/register"); // Перенаправляем пользователя на страницу регистрации
  };


  const deleteAccount = async () => {
    const customerId = localStorage.getItem("customerId");
    if (customerId) {
      try {
        const response = await axios.post(
          "https://magaz05.tw1.ru/php/delete_customer.php",
          { customerId }
        );
        if (response.status === 200) {
          localStorage.clear();
          navigate("/register");
        } else {
          setError("Ошибка при удалении аккаунта");
        }
      } catch (error) {
        setError("Ошибка при удалении аккаунта");
      }
    }
  };

  return (
    <Container>
      <h2 className="mt-4">Профиль</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>Имя</Form.Label>
          <Form.Control type="text" value={name} disabled />
        </Form.Group>
        <Form.Group controlId="formPhone" className="mt-3">
          <Form.Label>Телефон</Form.Label>
          <Form.Control type="text" value={phone} disabled />
        </Form.Group>
        <Button
          variant="primary"
          className="mt-3"
          onClick={() => setShowEditModal(true)}
        >
          Редактировать
        </Button>
      </Form>
      <Button
        variant="secondary"
        className="mt-3"
        onClick={() => navigate("/order-history")}
      >
        История заказов
      </Button>
      <Button variant="danger" className="mt-3" onClick={logout}>
        Выйти
      </Button>
      <Button variant="dark" className="mt-3" onClick={deleteAccount}>
        Удалить аккаунт
      </Button>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Редактировать профиль</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editName">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editPhone" className="mt-3">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              className="mt-3"
              onClick={() => {
                if (phone !== originalPhone) {
                  sendCode();
                  setShowVerifyModal(true);
                } else {
                  updateUserData();
                  setShowEditModal(false);
                }
              }}
            >
              Сохранить
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showVerifyModal}
        onHide={() => setShowVerifyModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Подтвердить номер</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="verifyCode">
              <Form.Label>Код подтверждения</Form.Label>
              <Form.Control
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              className="mt-3"
              onClick={verifyAndSaveCode}
            >
              Подтвердить и сохранить
            </Button>
            <Button
              variant="danger"
              className="mt-3"
              onClick={restoreOriginalData}
            >
              Отмена
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProfilePage;
