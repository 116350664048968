import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ListGroup,
  Button,
  Image,
  Form,
  Alert,
} from "react-bootstrap";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [isPickup, setIsPickup] = useState(false);
  const [error, setError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [isCheckout, setIsCheckout] = useState(false); // Флаг для отображения шага оформления заказа
  const navigate = useNavigate();

  useEffect(() => {
    loadCustomerId();
  }, []);

  const loadCustomerId = async () => {
    const customerId = localStorage.getItem("customerId");
    if (customerId) {
      setCustomerId(customerId);
      fetchCartItems(customerId);
      loadCustomerInfo(customerId);
    } else {
      alert("Customer ID not found");
      console.log("Customer ID not found in localStorage");
    }
  };

  const fetchCartItems = async (customerId) => {
    try {
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/get_cart_items.php?customer_id=${customerId}`
      );
      if (response.status === 200) {
        setCartItems(response.data);
      } else {
        alert("Ошибка при получении списка товаров");
        console.log("Error fetching cart items:", response.status);
      }
    } catch (error) {
      alert("Ошибка при получении списка товаров");
      console.log("Error fetching cart items:", error);
    }
  };

  const loadCustomerInfo = async (customerId) => {
    try {
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/get_customer.php?customerId=${customerId}`
      );
      if (response.status === 200) {
        const customerData = response.data;
        setPhone(customerData.phone || "");
        setCurrentAddress(customerData.address || "");
      } else {
        console.log("Error fetching customer info:", response.status);
      }
    } catch (error) {
      console.log("Error fetching customer info:", error);
    }
  };

  const removeFromCart = async (cartItemId) => {
    try {
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/remove_from_cart.php",
        { id: cartItemId }
      );
      if (response.status === 200) {
        alert("Товар удален из корзины");
        fetchCartItems(customerId);
      } else {
        alert("Ошибка при удалении товара из корзины");
      }
    } catch (error) {
      alert("Ошибка при удалении товара из корзины");
      console.log("Error removing item from cart:", error);
    }
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      return total + parseFloat(item.price) * item.quantity;
    }, 0);
  };

  const confirmOrder = async () => {
    const currentDateTime = new Date().toISOString();
    try {
      const requestBody = {
        customerId,
        items: cartItems,
        total: calculateTotal(),
        address: isPickup ? "Самовывоз" : currentAddress,
        phone,
        datetime: currentDateTime,
      };

      const response = await axios.post(
        "https://magaz05.tw1.ru/php/save_order.php",
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        alert("Заказ успешно оформлен");
        await clearCart(); // Очистка корзины после успешного заказа
        navigate("/order-history");
      } else {
        throw new Error("Ошибка при оформлении заказа");
      }
    } catch (error) {
      setError("Ошибка при оформлении заказа");
      console.log("Order submission error:", error);
    }
  };

  const clearCart = async () => {
    try {
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/clear_cart.php",
        { customerId }
      );
      if (response.status === 200) {
        setCartItems([]); // Очистить корзину на клиенте после успешной очистки на сервере
        console.log("Корзина успешно очищена");
      } else {
        console.log("Ошибка при очистке корзины");
      }
    } catch (error) {
      console.log("Ошибка при очистке корзины:", error);
    }
  };

  // Функция получения местоположения и преобразования его в адрес
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
            );

            const address = response.data.address;
            const formattedAddress = `${address.road}, ${address.house_number}, ${address.city}, ${address.country}`;
            setCurrentAddress(formattedAddress);
          } catch (error) {
            console.log("Error fetching address:", error);
            setLocationError("Ошибка при получении адреса");
          }
        },
        (error) => {
          setLocationError("Ошибка при получении местоположения");
          console.log(error);
        }
      );
    } else {
      setLocationError("Геолокация не поддерживается вашим браузером");
    }
  };

  const proceedToCheckout = () => {
    setIsCheckout(true); // Переход к шагу оформления заказа
  };

  const formatPrice = (price) => {
    const numericPrice = parseFloat(price);
    return numericPrice.toFixed(numericPrice % 1 === 0 ? 0 : 2);
  };

  return (
    <Container>
      {!isCheckout ? (
        <>
          <h1 className="my-4">Корзина</h1>
          <ListGroup>
            {cartItems.map((cartItem) => (
              <ListGroup.Item
                key={cartItem.id}
                className="d-flex justify-content-between align-items-center"
              >
                <Image
                  src={`https://magaz05.tw1.ru/images/${cartItem.image}`}
                  thumbnail
                  width={100}
                />
                <div className="flex-grow-1 ms-3">
                  <h5>{cartItem.product_name}</h5>
                  <p>{formatPrice(cartItem.price)} ₽</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="me-3" style={{ fontSize: "18px" }}>
                    {cartItem.quantity}
                  </span>
                  <Button
                    variant="danger"
                    onClick={() => removeFromCart(cartItem.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div className="mt-4">
            <Button
              onClick={proceedToCheckout}
              variant="success"
              size="lg"
              block
            >
              Перейти к оформлению ({formatPrice(calculateTotal())} ₽)
            </Button>
          </div>
        </>
      ) : (
        <>
          <h1 className="my-4">Оформление заказа</h1>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Label>Адрес доставки</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите ваш адрес"
                value={isPickup ? "Самовывоз" : currentAddress}
                onChange={(e) => setCurrentAddress(e.target.value)}
                disabled={isPickup}
              />
              <Button onClick={getLocation} className="mt-2">
                Получить мое местоположение
              </Button>
              {locationError && <Alert variant="danger">{locationError}</Alert>}
            </Form.Group>
            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label>Номер телефона</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите ваш номер телефона"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Check
              type="switch"
              id="pickup-switch"
              label="Самовывоз"
              checked={isPickup}
              onChange={(e) => setIsPickup(e.target.checked)}
            />
            <Button
              onClick={confirmOrder}
              variant="primary"
              size="lg"
              className="mt-3"
              block
            >
              Подтвердить заказ
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default CartPage;
