import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <Container>
      <h1>О нас</h1>
      <p>
        Добро пожаловать в DIR MARKET! Мы стремимся предоставить вам лучшие
        продукты и услуги. Наша цель - удовлетворить потребности каждого клиента
        и обеспечить высокое качество обслуживания.
      </p>
      <h2>Контактная информация</h2>
      <p>
        <strong>Телефон:</strong> 89885147009
        <br />
        <strong>Электронная почта:</strong> simolapps@gmail.com
      </p>
      <h2>Политика конфиденциальности</h2>
      <p>
        Мы в DIR MARKET заботимся о конфиденциальности и защите ваших личных
        данных. Нажмите на кнопку ниже, чтобы ознакомиться с нашей Политикой
        конфиденциальности.
      </p>
      <Button as={Link} to="/privacy-policy" variant="primary">
        Политика конфиденциальности
      </Button>
      <p className="mt-4">
        <strong>DIR MARKET</strong>
      </p>
    </Container>
  );
};

export default AboutPage;
