import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicyPage = () => {
  return (
    <Container>
      <h1>Политика конфиденциальности</h1>
      <p>
        <strong>Дата вступления в силу: 9 июля 2024 года</strong>
      </p>
      <p>
        Мы в DIR MARKET заботимся о конфиденциальности и защите ваших личных
        данных. Настоящая Политика конфиденциальности описывает, какие данные мы
        собираем, как мы их используем и защищаем, а также ваши права в
        отношении этих данных.
      </p>
      <h2>1. Собираемые данные</h2>
      <h3>1.1. Личные данные</h3>
      <p>
        Когда вы регистрируетесь на нашем сайте, оформляете заказ или
        используете другие функции нашего сайта, мы можем собирать следующую
        информацию:
      </p>
      <ul>
        <li>Имя</li>
        <li>Номер телефона</li>
        <li>Адрес</li>
      </ul>
      <h3>1.2. Данные о транзакциях</h3>
      <p>Мы собираем информацию о покупках и транзакциях, таких как:</p>
      <ul>
        <li>Информация о заказанных товарах</li>
        <li>Суммы и даты транзакций</li>
        <li>Способы оплаты</li>
      </ul>
      <h3>1.3. Технические данные</h3>
      <p>
        Мы автоматически собираем определенную информацию при посещении нашего
        сайта, такую как:
      </p>
      <ul>
        <li>IP-адрес</li>
        <li>Тип браузера</li>
        <li>Операционная система</li>
        <li>История просмотров и взаимодействия с нашим сайтом</li>
      </ul>
      <h3>1.4. Согласие на SMS-рассылку</h3>
      <p>
        При регистрации на нашем сайте или оформлении заказа вы соглашаетесь на
        получение SMS-рассылок от нас. Вы можете отказаться от рассылки в любое
        время, следуя инструкциям, указанным в сообщении.
      </p>
      <h2>2. Использование данных</h2>
      <p>Мы используем собранные данные для следующих целей:</p>
      <ul>
        <li>Обработка и выполнение ваших заказов</li>
        <li>Обеспечение поддержки клиентов</li>
        <li>Улучшение нашего сайта и услуг</li>
        <li>Персонализация вашего опыта</li>
        <li>Отправка рекламных материалов и уведомлений (с вашего согласия)</li>
      </ul>
      <h2>3. Передача данных третьим лицам</h2>
      <p>
        Мы не продаем и не передаем ваши личные данные третьим лицам, за
        исключением следующих случаев:
      </p>
      <ul>
        <li>
          Когда это необходимо для выполнения ваших заказов (например, передача
          данных курьерским службам)
        </li>
        <li>
          Когда это требуется по закону или по запросу государственных органов
        </li>
        <li>Когда вы даете на это явное согласие</li>
      </ul>
      <h2>4. Защита данных</h2>
      <p>
        Мы принимаем соответствующие технические и организационные меры для
        защиты ваших данных от несанкционированного доступа, изменения,
        раскрытия или уничтожения. Наши меры безопасности включают:
      </p>
      <ul>
        <li>Использование SSL для шифрования данных</li>
        <li>
          Ограничение доступа к вашим данным только для сотрудников, которым это
          необходимо для выполнения своих обязанностей
        </li>
      </ul>
      <h2>5. Ваши права</h2>
      <p>Вы имеете право:</p>
      <ul>
        <li>Получить доступ к своим личным данным</li>
        <li>Исправить или обновить свои данные</li>
        <li>Удалить свои данные</li>
        <li>Ограничить обработку своих данных</li>
        <li>
          Возразить против использования своих данных в маркетинговых целях
        </li>
      </ul>
      <p>
        Для реализации своих прав, пожалуйста, свяжитесь с нами по адресу
        электронной почты: simolapps@gmail.com.
      </p>
      <h2>6. Изменения в Политике конфиденциальности</h2>
      <p>
        Мы можем время от времени обновлять настоящую Политику
        конфиденциальности. Все изменения будут опубликованы на этой странице, и
        дата вступления в силу будет обновлена. Мы рекомендуем периодически
        просматривать эту страницу, чтобы быть в курсе любых изменений.
      </p>
      <h2>7. Контактная информация</h2>
      <p>
        Если у вас есть вопросы или предложения по поводу нашей Политики
        конфиденциальности, пожалуйста, свяжитесь с нами по адресу электронной
        почты: simolapps@gmail.com или по телефону: 89885147009.
      </p>
      <h2>8. Паспортные данные</h2>
      <p>
        Паспорт выдан: ТП. УФМС РОССИИ ПО РЕСПУБЛИКЕ ДАГЕСТАН В ШАМИЛЬСКОМ
        РАЙОНЕ
      </p>
      <p>Дата выдачи: 13.04.2010</p>
      <p>Код подразделения: 050-049</p>
      <p>Серия и номер: 82 09 814603</p>
      <p>ФИО: Омаров Магомеднакшубанди Сайтверович</p>
      <p>Дата рождения: 26.01.1990</p>
      <p>Место рождения: с. Тидив Шамильского р-на Республики Дагестан</p>
      <p>
        <strong>DIR MARKET</strong>
      </p>
    </Container>
  );
};

export default PrivacyPolicyPage;
