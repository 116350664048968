import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://magaz05.tw1.ru/php/get_categories.php"
      );
      if (response.status === 200) {
        setCategories(response.data);
      } else {
        alert("Ошибка при получении списка категорий");
      }
    } catch (error) {
      alert("Ошибка при получении списка категорий");
    }
  };

  const openCategoryProducts = (categoryId) => {
    navigate(`/products?categoryId=${categoryId}`);
  };

  return (
    <Container className="mt-4">
      <Row>
        {categories.map((category) => (
          <Col key={category.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card onClick={() => openCategoryProducts(category.id)}>
              <Card.Img
                variant="top"
                src={`https://magaz05.tw1.ru/images/${category.image}`}
                alt={category.name}
              />
              <Card.Body>
                <Card.Title>{category.name}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CategoriesPage;
