import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";

const OrderHistoryPage = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const customerId = localStorage.getItem("customerId");
      if (customerId) {
        try {
          const response = await axios.get(
            `https://magaz05.tw1.ru/php/get_orders.php?customer_id=${customerId}`
          );
          setOrders(response.data);
        } catch (error) {
          console.error("Ошибка при получении истории заказов:", error);
        }
      }
    };

    fetchOrders();
  }, []);

  const handleOrderClick = (orderId) => {
    navigate(`/order-details/${orderId}`);
  };

  return (
    <Container>
      <Row>
        {orders.map((order) => (
          <Col key={order.id} xs={12} md={6} lg={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>Заказ №{order.id}</Card.Title>
                <Card.Text>
                  Дата: {new Date(order.order_date).toLocaleDateString()}
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => handleOrderClick(order.id)}
                >
                  Посмотреть детали
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default OrderHistoryPage;
