import React, { useState, useEffect } from "react";
import { Modal, Button, Image, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as solidHeart,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";

const ProductDetailSheet = ({ product, show, handleClose }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    checkIfFavorite();
  }, [product]);

  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const checkIfFavorite = async () => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/check_favorite.php?customer_id=${customerId}&product_id=${product.id}`
      );
      if (response.status === 200) {
        setIsFavorite(response.data.is_favorite);
      }
    } catch (error) {
      console.error("Ошибка при проверке избранного:", error);
    }
  };

  const toggleFavorite = async () => {
    const customerId = localStorage.getItem("customerId");
    if (isFavorite) {
      await axios.post("https://magaz05.tw1.ru/php/remove_from_favorites.php", {
        customer_id: customerId,
        product_id: product.id,
      });
      setIsFavorite(false);
    } else {
      await axios.post("https://magaz05.tw1.ru/php/add_to_favorites.php", {
        customer_id: customerId,
        product_id: product.id,
      });
      setIsFavorite(true);
    }
  };

  const addToCart = async () => {
    const customerId = localStorage.getItem("customerId");
    await axios.post("https://magaz05.tw1.ru/php/add_to_cart.php", {
      customer_id: customerId,
      product_id: product.id,
      quantity: quantity,
    });
    alert("Товар добавлен в корзину");
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{product.name}</Modal.Title> {/* Only one title */}
      </Modal.Header>
      <Modal.Body>
        <Image
          src={`https://magaz05.tw1.ru/images/${product.image}`}
          fluid
          className="mb-3"
        />
        <p>{product.description}</p>
        <p>
          Количество: {product.measurement_quantity} {product.measurement_unit}
        </p>
        <h4>Цена: {product.price} ₽</h4>

        <div className="d-flex justify-content-between align-items-center">
          <Button variant="secondary" onClick={decrementQuantity}>
            -
          </Button>
          <span>{quantity}</span>
          <Button variant="secondary" onClick={incrementQuantity}>
            +
          </Button>
        </div>

        <Button className="mt-3" variant="success" onClick={addToCart}>
          <FontAwesomeIcon icon={faCartPlus} /> Добавить в корзину
        </Button>

        <Button className="mt-3 ml-3" variant="link" onClick={toggleFavorite}>
          <FontAwesomeIcon
            icon={isFavorite ? solidHeart : regularHeart}
            color={isFavorite ? "red" : "black"}
          />{" "}
          {isFavorite ? "Удалить из избранного" : "Добавить в избранное"}
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailSheet;
