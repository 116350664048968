import React, { useState, useEffect } from "react";
import axios from "axios";
import { ListGroup, Image, Button, Modal, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProductDetailSheet from "../components/ProductDetailSheet";
import "./FavoritesPage.css";

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadCustomerId();
  }, []);

  const loadCustomerId = async () => {
    const savedCustomerId = localStorage.getItem("customerId");
    if (savedCustomerId) {
      setCustomerId(savedCustomerId);
      fetchFavorites(savedCustomerId);
    } else {
      navigate("/register"); // Перенаправление на страницу регистрации
    }
  };

  const fetchFavorites = async (customerId) => {
    try {
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/get_favorites.php?customer_id=${customerId}`
      );
      if (response.status === 200) {
        setFavorites(response.data);
      } else {
        alert("Ошибка при получении списка избранного");
      }
    } catch (error) {
      alert("Ошибка при получении списка избранного");
    }
  };

  const removeFromFavorites = async (productId) => {
    try {
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/remove_from_favorites.php",
        {
          customer_id: customerId,
          product_id: productId,
        }
      );
      if (response.status === 200) {
        setFavorites(favorites.filter((item) => item.product_id !== productId));
        alert("Товар удален из избранного");
      } else {
        alert("Ошибка удаления товара из избранного");
      }
    } catch (error) {
      alert("Ошибка удаления товара из избранного");
    }
  };

  const showProductDetails = async (productId) => {
    try {
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/get_product_by_id.php?id=${productId}`
      );
      if (response.status === 200) {
        setSelectedProduct(response.data);
        setShowModal(true);
      } else {
        alert("Ошибка при получении данных товара");
      }
    } catch (error) {
      alert("Ошибка при получении данных товара");
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="favorites-container">
      <ListGroup variant="flush">
        {favorites.map((favorite) => (
          <ListGroup.Item key={favorite.product_id} className="favorites-item">
            <Row>
              <Col xs={3} md={2} lg={1}>
                <Image
                  src={`https://magaz05.tw1.ru/images/${favorite.image || ""}`}
                  thumbnail
                />
              </Col>
              <Col xs={6} md={8} lg={9}>
                <div
                  onClick={() => showProductDetails(favorite.product_id)}
                  style={{ cursor: "pointer" }}
                >
                  <h5>{favorite.product_name || "Название отсутствует"}</h5>
                  <p>{favorite.price || "Цена отсутствует"} ₽</p>
                </div>
              </Col>
              <Col xs={3} md={2} lg={2} className="text-right">
                <Button
                  variant="danger"
                  onClick={() => removeFromFavorites(favorite.product_id)}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {selectedProduct && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductDetailSheet product={selectedProduct} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default FavoritesPage;
