import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import { Navbar, Nav, Container, Image, Button, Modal } from "react-bootstrap";
import RegisterPage from "./pages/RegisterPage";
import ProductListPage from "./pages/ProductListPage";
import CategoriesPage from "./pages/CategoriesPage";
import FavoritesPage from "./pages/FavoritesPage";
import ProfilePage from "./pages/ProfilePage";
import CartPage from "./pages/CartPage";
import OrderDetailPage from "./pages/OrderDetailPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import OrderHistoryPage from "./pages/OrderHistoryPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage"; // Импортируем новую страницу
import logo from "./assets/logo.png";
import "./App.css";

// Функция для определения мобильного устройства
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const PrivateRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const customerId = localStorage.getItem("customerId");
    setIsAuthenticated(!!customerId);
    setLoading(false);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return isAuthenticated ? element : <Navigate to="/register" />;
};

// Компонент для модального окна с кнопками ссылок
const DownloadAppModal = ({ show, handleClose }) => (
  <Modal show={show} centered>
    <Modal.Header closeButton>
      <Modal.Title>Скачайте наше приложение</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center">
      <p>Для более удобного использования, скачайте наше приложение:</p>
      <Button
        variant="primary"
        href="https://play.google.com/store/apps/details?id=com.simolapps.dirmarket"
        target="_blank"
        className="mb-3"
      >
        Скачать с Google Play для Андроид
      </Button>
      <br />
      <Button
        variant="primary"
        href="https://apps.apple.com/ru/app/dirmarket/id6473123475"
        target="_blank"
      >
        Скачать из App Store для iPhone
      </Button>
    </Modal.Body>
  </Modal>
);

const Footer = () => (
  <footer className="bg-light text-center text-lg-start mt-4">
    <Container className="p-4">
      <div className="d-flex justify-content-between">
        <div>
          <h5 className="text-uppercase">Контактная информация</h5>
          <p>Телефон: 89885147009</p>
          <p>Электронная почта: simolapps@gmail.com</p>
        </div>
        <div>
          <h5 className="text-uppercase">Ссылки</h5>
          <ul className="list-unstyled">
            <li>
              <Link to="/about">О нас</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Политика конфиденциальности</Link>
            </li>
          </ul>
        </div>
      </div>
    </Container>
    <div className="text-center p-3 bg-light">
      &copy; {new Date().getFullYear()} DIR MARKET
    </div>
  </footer>
);

function App() {
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    if (isMobileDevice()) {
      setShowDownloadModal(true); // Показываем модальное окно, если зашел с мобильного устройства
    }
  }, []);

  const handleCloseModal = () => setShowDownloadModal(false);

  return (
    <Router>
      <Navbar bg="light" expand="lg" className="justify-content-center">
        <Container className="d-flex justify-content-between align-items-center">
          <Navbar.Brand
            as={Link}
            to="/"
            className="d-flex align-items-center text-success"
          >
            <Image
              src={logo}
              height="30"
              className="d-inline-block align-top mr-2"
              alt="DIR MARKET Logo"
            />
            DIR MARKET
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav>
              <Nav.Link as={Link} to="/" className="text-success">
                Главная
              </Nav.Link>
              <Nav.Link as={Link} to="/categories" className="text-success">
                Категории
              </Nav.Link>
              <Nav.Link as={Link} to="/favorites" className="text-success">
                Избранные
              </Nav.Link>
              <Nav.Link as={Link} to="/profile" className="text-success">
                Профиль
              </Nav.Link>
              <Nav.Link as={Link} to="/cart" className="text-success">
                Корзина
              </Nav.Link>
              <Nav.Link as={Link} to="/order-history" className="text-success">
                История заказов
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="text-success">
                О нас
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={<ProductListPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/favorites" element={<FavoritesPage />} />
          <Route
            path="/profile"
            element={<PrivateRoute element={<ProfilePage />} />}
          />
          <Route
            path="/cart"
            element={<PrivateRoute element={<CartPage />} />}
          />
          <Route
            path="/order-details/:orderId"
            element={<PrivateRoute element={<OrderDetailPage />} />}
          />
          <Route path="/product/:productId" element={<ProductDetailPage />} />
          <Route
            path="/order-history"
            element={<PrivateRoute element={<OrderHistoryPage />} />}
          />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </Container>
      <Footer />

      {/* Модальное окно для скачивания приложения */}
      <DownloadAppModal
        show={showDownloadModal}
        handleClose={handleCloseModal}
      />
    </Router>
  );
}

export default App;
