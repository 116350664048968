import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col, Modal } from "react-bootstrap";
import "./RegisterPage.css"; // Импорт файла стилей

function RegisterPage({ onRegistrationComplete }) {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [customerExists, setCustomerExists] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [canResendCode, setCanResendCode] = useState(true);
  const [resendCodeTimer, setResendCodeTimer] = useState(120);
  const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (!canResendCode) {
      timer = setInterval(() => {
        setResendCodeTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timer);
            setCanResendCode(true);
            return 120;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canResendCode]);

  const sendCode = async () => {
    try {
      console.log("Отправка кода на номер:", phone);
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/send_code.php",
        { phone }
      );
      if (response.status === 200) {
        setIsCodeSent(true);
        setCanResendCode(false);
        alert("Код отправлен на ваш номер телефона");
      } else {
        console.error("Ошибка отправки кода:", response);
        alert("Ошибка отправки кода");
      }
    } catch (error) {
      console.error("Ошибка отправки кода:", error);
      alert("Ошибка отправки кода");
    }
  };

  const verifyCode = async () => {
    try {
      console.log("Проверка кода для номера:", phone, "и кода:", code);
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/verify_code.php",
        {
          phone,
          code,
        }
      );
      if (response.data.success) {
        if (response.data.customer_exists) {
          localStorage.setItem("customerId", response.data.customer.id);
          localStorage.setItem("customerName", response.data.customer.name);
          setRegistrationComplete(true);
          if (onRegistrationComplete) {
            onRegistrationComplete(); // Проверка перед вызовом функции
          }
        } else {
          setIsCodeVerified(true);
          setCustomerExists(false);
          alert("Код успешно подтвержден. Пожалуйста, зарегистрируйтесь.");
        }
      } else {
        console.error("Неверный код:", response.data.message);
        alert("Неверный код: " + response.data.message);
      }
    } catch (error) {
      console.error("Ошибка проверки кода:", error);
      alert("Ошибка проверки кода");
    }
  };

  const registerName = async () => {
    try {
      console.log("Регистрация имени для номера:", phone, "и имени:", name);
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/register_name.php",
        {
          name,
          phone,
        }
      );
      if (response.data.success) {
        localStorage.setItem("customerId", response.data.customer.id);
        localStorage.setItem("customerName", response.data.customer.name);
        setRegistrationComplete(true);
        if (onRegistrationComplete) {
          onRegistrationComplete(); // Проверка перед вызовом функции
        }
      } else {
        console.error("Ошибка регистрации:", response.data.error);
        alert("Ошибка регистрации: " + response.data.error);
      }
    } catch (error) {
      console.error("Ошибка регистрации:", error);
      alert("Ошибка регистрации");
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="6">
          <h5 className="text-center">Регистрация по номеру телефона</h5>
          <Form className="register-form">
            <Form.Group controlId="formPhone">
              <Form.Label>Номер телефона</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите номер телефона"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            {isCodeSent && (
              <Form.Group controlId="formCode">
                <Form.Label>Код подтверждения</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Введите код подтверждения"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Group>
            )}
            {isCodeVerified && !customerExists && (
              <Form.Group controlId="formName">
                <Form.Label>Имя</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Введите ваше имя"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group controlId="formAgreeToPrivacyPolicy">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    Я согласен с{" "}
                    <a href="#" onClick={() => setShowPrivacyPolicy(true)}>
                      политикой конфиденциальности
                    </a>
                  </span>
                }
                checked={agreeToPrivacyPolicy}
                onChange={(e) => setAgreeToPrivacyPolicy(e.target.checked)}
              />
            </Form.Group>
            {!isCodeSent && (
              <Button
                variant="primary"
                onClick={canResendCode ? sendCode : null}
                disabled={!canResendCode || !agreeToPrivacyPolicy}
                className="w-100 mt-3"
              >
                Отправить код
              </Button>
            )}
            {isCodeSent && !isCodeVerified && !registrationComplete && (
              <Button
                variant="primary"
                onClick={verifyCode}
                className="w-100 mt-3"
              >
                Подтвердить код
              </Button>
            )}
            {isCodeVerified && !customerExists && !registrationComplete && (
              <Button
                variant="primary"
                onClick={registerName}
                className="w-100 mt-3"
              >
                Зарегистрировать имя
              </Button>
            )}
            {registrationComplete && (
              <Button
                variant="success"
                onClick={() => {
                  navigate("/"); // Перенаправление на главную страницу после успешной регистрации
                  if (onRegistrationComplete) {
                    onRegistrationComplete(); // Проверка перед вызовом функции
                  }
                }}
                className="w-100 mt-3"
              >
                Готово!
              </Button>
            )}
            {!canResendCode && (
              <p className="text-danger mt-3">
                Повторная отправка кода через: {resendCodeTimer} секунд
              </p>
            )}
          </Form>
        </Col>
      </Row>

      <Modal
        show={showPrivacyPolicy}
        onHide={() => setShowPrivacyPolicy(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Политика конфиденциальности</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <h1>Политика конфиденциальности</h1>
            <p>
              <strong>Дата вступления в силу: 9 июля 2024 года</strong>
            </p>
            <p>
              Мы в DIR MARKET заботимся о конфиденциальности и защите ваших
              личных данных. Настоящая Политика конфиденциальности описывает,
              какие данные мы собираем, как мы их используем и защищаем, а также
              ваши права в отношении этих данных.
            </p>
            <h2>1. Собираемые данные</h2>
            <h3>1.1. Личные данные</h3>
            <p>
              Когда вы регистрируетесь на нашем сайте, оформляете заказ или
              используете другие функции нашего сайта, мы можем собирать
              следующую информацию:
            </p>
            <ul>
              <li>Имя</li>
              <li>Номер телефона</li>
              <li>Адрес</li>
            </ul>
            <h3>1.2. Данные о транзакциях</h3>
            <p>Мы собираем информацию о покупках и транзакциях, таких как:</p>
            <ul>
              <li>Информация о заказанных товарах</li>
              <li>Суммы и даты транзакций</li>
              <li>Способы оплаты</li>
            </ul>
            <h3>1.3. Технические данные</h3>
            <p>
              Мы автоматически собираем определенную информацию при посещении
              нашего сайта, такую как:
            </p>
            <ul>
              <li>IP-адрес</li>
              <li>Тип браузера</li>
              <li>Операционная система</li>
              <li>История просмотров и взаимодействия с нашим сайтом</li>
            </ul>
            <h3>1.4. Согласие на SMS-рассылку</h3>
            <p>
              При регистрации на нашем сайте или оформлении заказа вы
              соглашаетесь на получение SMS-рассылок от нас. Вы можете
              отказаться от рассылки в любое время, следуя инструкциям,
              указанным в сообщении.
            </p>
            <h2>2. Использование данных</h2>
            <p>Мы используем собранные данные для следующих целей:</p>
            <ul>
              <li>Обработка и выполнение ваших заказов</li>
              <li>Обеспечение поддержки клиентов</li>
              <li>Улучшение нашего сайта и услуг</li>
              <li>Персонализация вашего опыта</li>
              <li>
                Отправка рекламных материалов и уведомлений (с вашего согласия)
              </li>
            </ul>
            <h2>3. Передача данных третьим лицам</h2>
            <p>
              Мы не продаем и не передаем ваши личные данные третьим лицам, за
              исключением следующих случаев:
            </p>
            <ul>
              <li>
                Когда это необходимо для выполнения ваших заказов (например,
                передача данных курьерским службам)
              </li>
              <li>
                Когда это требуется по закону или по запросу государственных
                органов
              </li>
              <li>Когда вы даете на это явное согласие</li>
            </ul>
            <h2>4. Защита данных</h2>
            <p>
              Мы принимаем соответствующие технические и организационные меры
              для защиты ваших данных от несанкционированного доступа,
              изменения, раскрытия или уничтожения. Наши меры безопасности
              включают:
            </p>
            <ul>
              <li>Использование SSL для шифрования данных</li>
              <li>
                Ограничение доступа к вашим данным только для сотрудников,
                которым это необходимо для выполнения своих обязанностей
              </li>
            </ul>
            <h2>5. Ваши права</h2>
            <p>Вы имеете право:</p>
            <ul>
              <li>Получить доступ к своим личным данным</li>
              <li>Исправить или обновить свои данные</li>
              <li>Удалить свои данные</li>
              <li>Ограничить обработку своих данных</li>
              <li>
                Возразить против использования своих данных в маркетинговых
                целях
              </li>
            </ul>
            <p>
              Для реализации своих прав, пожалуйста, свяжитесь с нами по адресу
              электронной почты: simolapps@gmail.com.
            </p>
            <h2>6. Изменения в Политике конфиденциальности</h2>
            <p>
              Мы можем время от времени обновлять настоящую Политику
              конфиденциальности. Все изменения будут опубликованы на этой
              странице, и дата вступления в силу будет обновлена. Мы рекомендуем
              периодически просматривать эту страницу, чтобы быть в курсе любых
              изменений.
            </p>
            <h2>7. Контактная информация</h2>
            <p>
              Если у вас есть вопросы или предложения по поводу нашей Политики
              конфиденциальности, пожалуйста, свяжитесь с нами по адресу
              электронной почты: simolapps@gmail.com или по телефону:
              89885147009.
            </p>
            <h2>8. Паспортные данные</h2>
            <p>
              Паспорт выдан: ТП. УФМС РОССИИ ПО РЕСПУБЛИКЕ ДАГЕСТАН В ШАМИЛЬСКОМ
              РАЙОНЕ
            </p>
            <p>Дата выдачи: 13.04.2010</p>
            <p>Код подразделения: 050-049</p>
            <p>Серия и номер: 82 09 814603</p>
            <p>ФИО: Омаров Магомеднакшубанди Сайтверович</p>
            <p>Дата рождения: 26.01.1990</p>
            <p>Место рождения: с. Тидив Шамильского р-на Республики Дагестан</p>
            <p>
              <strong>DIR MARKET</strong>
            </p>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPrivacyPolicy(false)}
          >
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default RegisterPage;
