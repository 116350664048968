import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  FormControl,
  InputGroup,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as solidHeart,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import "./ProductListPage.css";

// Настройка стилей для react-modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // Увеличиваем ширину окна
    maxHeight: "90vh", // Увеличиваем максимальную высоту
    overflowY: "auto", // Добавляем скролл, если контент превышает высоту
  },
};

const ProductListPage = ({ categoryId }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellersWithProducts, setSellersWithProducts] = useState([]);
  const [selectedSellerId, setSelectedSellerId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [defaultPrice, setDefaultPrice] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      await fetchSellers(); // Загружаем продавцов
      await fetchProducts(); // Загружаем продукты
    };
    loadData();
  }, []);

  useEffect(() => {
    if (products.length && sellers.length) {
      identifySellersWithProducts(products); // Определяем продавцов с продуктами
    }
  }, [products, sellers]);

  useEffect(() => {
    applyFilters(); // Применение фильтров при изменении поиска или выбранного продавца
  }, [searchTerm, selectedSellerId]);

  // Получение продуктов с сервера
  const fetchProducts = async () => {
    const url = categoryId
      ? `https://magaz05.tw1.ru/php/get_products.php?category_id=${categoryId}`
      : "https://magaz05.tw1.ru/php/get_products.php";

    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        const productsData = response.data;
        setProducts(productsData); // Сохраняем продукты
        setFilteredProducts(
          productsData.filter((product) => product.is_available === 1)
        ); // Фильтруем доступные продукты
      } else {
        console.error("Error fetching products:", response);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Получение продавцов с сервера
  const fetchSellers = async () => {
    try {
      const response = await axios.get(
        "https://magaz05.tw1.ru/php/get_sellers.php"
      );
      if (response.status === 200) {
        setSellers(response.data); // Сохраняем продавцов
      } else {
        console.error("Error fetching sellers:", response);
      }
    } catch (error) {
      console.error("Error fetching sellers:", error);
    }
  };

  // Определение продавцов с продуктами
  const identifySellersWithProducts = (products) => {
    const sellerIdsWithProducts = new Set(
      products.map((product) => product.user_id)
    );
    const sellersWithProducts = sellers.filter((seller) =>
      sellerIdsWithProducts.has(parseInt(seller.id))
    );
    setSellersWithProducts([{ id: null, name: "ВСЕ" }, ...sellersWithProducts]); // Добавляем фильтр "ВСЕ"
  };

  // Применение фильтров
  const applyFilters = () => {
    const filtered = products.filter((product) => {
      const matchesSearch =
        searchTerm === "" ||
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (product.description &&
          product.description.toLowerCase().includes(searchTerm.toLowerCase()));
      const matchesSeller =
        selectedSellerId === null || product.user_id == selectedSellerId;
      return product.is_available === 1 && matchesSearch && matchesSeller;
    });
    setFilteredProducts(filtered);
  };

  // Обработка изменения поиска
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Обработка выбора продавца
  const handleSellerSelect = (sellerId) => {
    setSelectedSellerId(sellerId);
  };

  // Открытие модального окна с деталями продукта
  const handleProductClick = async (product) => {
    const customerId = localStorage.getItem("customerId");
    if (!customerId) {
      navigate("/register");
    } else {
      setSelectedProduct(product);
      checkIfFavorite(product.id);
      setDefaultPrice(product.price);
      await fetchVariants(product.id);
      setShowModal(true);
    }
  };

  // Получение вариантов товара
  const fetchVariants = async (productId) => {
    try {
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/get_variants.php?product_id=${productId}`
      );
      if (response.status === 200) {
        setVariants(response.data); // Сохраняем варианты
        setSelectedVariant(null); // Оставляем цену по умолчанию
      }
    } catch (error) {
      console.error("Ошибка при получении вариантов товара:", error);
    }
  };

  // Обработка выбора варианта
  const handleVariantSelect = (variant) => {
    setSelectedVariant(variant); // Устанавливаем выбранный вариант
  };

  // Закрытие модального окна
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Увеличение количества
  const incrementQuantity = () => setQuantity(quantity + 1);
  // Уменьшение количества
  const decrementQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  // Проверка, в избранном ли товар
  const checkIfFavorite = async (productId) => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await axios.get(
        `https://magaz05.tw1.ru/php/check_favorite.php?customer_id=${customerId}&product_id=${productId}`
      );
      if (response.status === 200) {
        setIsFavorite(response.data.is_favorite);
      }
    } catch (error) {
      console.error("Ошибка при проверке избранного:", error);
    }
  };

  // Добавление/удаление из избранного
  const toggleFavorite = async () => {
    const customerId = localStorage.getItem("customerId");
    if (isFavorite) {
      await axios.post("https://magaz05.tw1.ru/php/remove_from_favorites.php", {
        customer_id: customerId,
        product_id: selectedProduct.id,
      });
      setIsFavorite(false);
    } else {
      await axios.post("https://magaz05.tw1.ru/php/add_to_favorites.php", {
        customer_id: customerId,
        product_id: selectedProduct.id,
      });
      setIsFavorite(true);
    }
  };

  // Добавление товара в корзину
  const addToCart = async () => {
    const customerId = localStorage.getItem("customerId");

    if (!customerId) {
      alert("Вы не авторизованы. Пожалуйста, войдите или зарегистрируйтесь.");
      navigate("/register");
      return;
    }

    const payload = {
      customer_id: customerId,
      product_id: selectedProduct.id,
      variant_id: selectedVariant?.id || null,
      quantity: quantity,
    };

    try {
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/add_to_cart.php",
        payload
      );

      if (response.status === 200) {
        alert("Товар добавлен в корзину");
      } else {
        alert("Ошибка добавления товара в корзину");
      }
    } catch (error) {
      console.error("Ошибка при добавлении товара в корзину:", error);
      alert("Произошла ошибка при добавлении товара в корзину.");
    }

    handleCloseModal(); // Закрытие модального окна после добавления товара
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <InputGroup>
            <FormControl
              placeholder="Поиск..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setSearchTerm("")}
            >
              Очистить
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        {sellersWithProducts.map((seller) => (
          <Col key={seller.id} xs="auto">
            <Button
              variant={selectedSellerId === seller.id ? "primary" : "secondary"}
              onClick={() => handleSellerSelect(seller.id)}
            >
              {seller.name}
            </Button>
          </Col>
        ))}
      </Row>
      <Row>
        {filteredProducts.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card
              className="product-card h-100"
              onClick={() => handleProductClick(product)}
            >
              <Card.Img
                className="product-image"
                variant="top"
                src={`https://magaz05.tw1.ru/images/${product.image}`}
              />
              <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text className="price-text">{product.price} ₽</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedProduct && (
        <Modal
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          style={customStyles}
          contentLabel="Product Details Modal"
        >
          <h2>{selectedProduct.name}</h2>
          <div className="text-center">
            <Image
              src={`https://magaz05.tw1.ru/images/${selectedProduct.image}`}
              fluid
              className="mb-3"
              style={{ maxHeight: "300px", objectFit: "contain" }}
            />
          </div>
          <p>{selectedProduct.description}</p>
          <p>
            Количество:{" "}
            {selectedVariant?.measurement_quantity ||
              selectedProduct.measurement_quantity}{" "}
            {selectedVariant?.measurement_unit ||
              selectedProduct.measurement_unit}
          </p>
          <h4>
            Цена: {selectedVariant ? selectedVariant.price : defaultPrice} ₽
          </h4>

          {variants.length > 0 && (
            <Row className="mb-3">
              <Col>
                <Button
                  variant={selectedVariant === null ? "primary" : "light"}
                  onClick={() => setSelectedVariant(null)}
                >
                  {selectedProduct.measurement_quantity}{" "}
                  {selectedProduct.measurement_unit} - {defaultPrice} ₽
                </Button>
              </Col>
              {variants.map((variant) => (
                <Col key={variant.id}>
                  <Button
                    variant={
                      selectedVariant?.id === variant.id ? "primary" : "light"
                    }
                    onClick={() => handleVariantSelect(variant)}
                  >
                    {variant.measurement_quantity} {variant.measurement_unit} -{" "}
                    {variant.price} ₽
                  </Button>
                </Col>
              ))}
            </Row>
          )}

          <div
            className="d-flex justify-content-between align-items-center mt-3"
            style={{ flexWrap: "wrap" }}
          >
            <div className="d-flex align-items-center">
              <Button variant="secondary" onClick={decrementQuantity}>
                -
              </Button>
              <span className="mx-3">{quantity}</span>
              <Button variant="secondary" onClick={incrementQuantity}>
                +
              </Button>
            </div>

            <Button variant="link" onClick={toggleFavorite}>
              <FontAwesomeIcon
                icon={isFavorite ? solidHeart : regularHeart}
                color={isFavorite ? "red" : "black"}
              />
            </Button>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              className="mt-3"
            >
              Закрыть
            </Button>

            <Button variant="success" onClick={addToCart} className="mt-3">
              <FontAwesomeIcon icon={faCartPlus} /> Добавить в корзину
            </Button>
          </div>
        </Modal>
      )}
    </Container>
  );
};

export default ProductListPage;
