import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Spinner,
  Alert,
  Button,
  Modal,
} from "react-bootstrap";

const ProductDetailPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      console.log(`Fetching details for product ID: ${productId}`);
      try {
        const response = await axios.get(
          `https://magaz05.tw1.ru/php/get_product_by_id.php?id=${productId}`
        );
        if (response.status === 200) {
          console.log("Product details fetched:", response.data);
          setProduct(response.data);
        } else {
          console.log("Error fetching product details:", response);
          setError("Ошибка при получении данных товара");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
        setError("Ошибка при получении данных товара");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const checkRegistration = () => {
    const customerId = localStorage.getItem("customerId");
    if (!customerId) {
      console.log("User not registered, navigating to registration");
      navigate("/register");
      return false;
    }
    return true;
  };

  const addToCart = async () => {
    if (!checkRegistration()) return;

    try {
      const customerId = localStorage.getItem("customerId");
      console.log("Adding to cart:", { customerId, product });
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/add_to_cart.php",
        {
          customer_id: customerId,
          product_id: product.id,
          quantity: 1,
        }
      );
      if (response.status === 200) {
        console.log("Product added to cart");
        alert("Товар добавлен в корзину");
      } else {
        console.log("Error adding product to cart:", response);
        alert("Ошибка при добавлении товара в корзину");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
      alert("Ошибка при добавлении товара в корзину");
    }
  };

  const addToFavorites = async () => {
    if (!checkRegistration()) return;

    try {
      const customerId = localStorage.getItem("customerId");
      console.log("Adding to favorites:", { customerId, product });
      const response = await axios.post(
        "https://magaz05.tw1.ru/php/add_to_favorites.php",
        {
          customer_id: customerId,
          product_id: product.id,
        }
      );
      if (response.status === 200) {
        console.log("Product added to favorites");
        alert("Товар добавлен в избранное");
      } else {
        console.log("Error adding product to favorites:", response);
        alert("Ошибка при добавлении товара в избранное");
      }
    } catch (error) {
      console.error("Error adding product to favorites:", error);
      alert("Ошибка при добавлении товара в избранное");
    }
  };

  const handleModalClose = () => {
    console.log("Modal closed");
    setShowModal(false);
  };

  const handleModalOpen = () => {
    console.log("Modal opened");
    setShowModal(true);
  };

  if (loading) {
    console.log("Loading product details...");
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    console.error("Error:", error);
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!product) {
    console.log("Product not found");
    return <Alert variant="info">Данные о товаре не найдены</Alert>;
  }

  return (
    <Container>
      <h2 className="mt-4">{product.name}</h2>
      <Card className="mt-4" onClick={handleModalOpen}>
        <Card.Img
          variant="top"
          src={
            product.image
              ? `https://magaz05.tw1.ru/images/${product.image}`
              : "https://via.placeholder.com/150"
          } // Используй заглушку, если image отсутствует
        />
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <Card.Text>Цена: {product.price} ₽</Card.Text>
          <Button variant="success" className="mr-2">
            Подробнее
          </Button>
        </Card.Body>
      </Card>

      {/* Модальное окно */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{product.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card.Img
            variant="top"
            src={
              product.image
                ? `https://magaz05.tw1.ru/images/${product.image}`
                : "https://via.placeholder.com/150"
            }
          />
          <Card.Text>{product.description}</Card.Text>
          <Card.Text>Цена: {product.price} ₽</Card.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Закрыть
          </Button>
          <Button variant="success" onClick={addToCart}>
            Добавить в корзину
          </Button>
          <Button variant="primary" onClick={addToFavorites}>
            Добавить в избранное
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProductDetailPage;
